import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FontAwesomeIcon = makeShortcode("FontAwesomeIcon");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "qa--bugs"
    }}>{`QA > Bugs`}</h1>
    <p>{`On this page we discuss and document some of the oddest bugs (software deficiencies) we've observed over the years.`}</p>
    <p>{`Have you've seen some great bugs. Hit us up on `}<a parentName="p" {...{
        "href": "https://twitter.com/gxwheel152"
      }}><FontAwesomeIcon icon={['fab', 'twitter']} mdxType="FontAwesomeIcon" />{` Twitter`}</a>{`!`}</p>
    <h2 {...{
      "id": "squash-those-bugs--not-the-team-or-individual-that-inadvertently-injected-them-into-the-code"
    }}>{`Squash those bugs >>> Not the team or individual that inadvertently injected them into the code`}</h2>
    <FeedItems filterByTags={['qa-to-bugs']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      